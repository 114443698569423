import { gsap } from "gsap";

const cs = document.querySelector('.c-cursor');
const cursor = document.querySelector('.c-cursor__pointer');

class MouseCursor {
    constructor() {
        this.page = document.querySelector('#wrapper');

        gsap.to(cursor, {
            autoAlpha: 0,
        });
    }

    moveMousePos(e) {
        if (e.target.classList.contains('c-magnetic')) return;

        gsap.to(cursor, .4, {
            x: e.clientX,
            y: e.clientY,
        });
    }

    enterMouse() {
        gsap.to(cursor, .4, {
            autoAlpha: 1,
        });
    }

    handleMousePos() {
        this.page.addEventListener('mouseenter', this.enterMouse);
        this.page.addEventListener('mousemove', this.moveMousePos, false);
    }

    updateOnHover(e) {
        const { tagName, classList } = e.target;

        if (cs && !cs.classList.contains('is-loaded')) {
            cs.classList.add('is-loaded');
        }

        if (
            tagName === 'A' && !classList.contains('ignore-hover') ||
            tagName === 'BUTTON' ||
            tagName === 'INPUT' ||
            tagName === 'TEXTAREA' ||
            tagName === 'SELECT' ||
            classList.contains('is-cursor-hover') ||
            classList.contains('custom-control-label') ||
            classList.contains('btn') ||
            (e.target.parentElement.tagName === 'A' && e.target.tagName === 'IMG')
        ) {
            document.querySelector('html').classList.toggle('is-hover');
        }



        if (classList.contains('cs-hidden')) {
            document.querySelector('html').classList.toggle('is-hover--hidden');
        }

        classList.contains('c-cursor-drag') ? document.querySelector('html').classList.add('is-hover--drag') : document.querySelector('html').classList.remove('is-hover--drag');
        classList.contains('c-cursor-play') ? document.querySelector('html').classList.add('is-hover--play') : document.querySelector('html').classList.remove('is-hover--play');
    }

    handleLinkHover() {
        this.page.addEventListener('mouseover', this.updateOnHover.bind(this));
        this.page.addEventListener('mouseout', this.updateOnHover.bind(this));
    }

    render() {
        this.handleMousePos();
        this.handleLinkHover();
    }
}

class MagneticCursor {
    constructor() {
        this.links = [...document.querySelectorAll('.c-magnetic')];
        this.cursor = document.querySelector('.c-cursor__pointer');
        this.pos = { x: 0, y: 0 };
    }

    activateMagnetic() {
        this.links.map(link => {
            const that = this;
            link.addEventListener('mousemove', function(e) {
                that.moveTarget(e, this, this.querySelector('span'), 15);
                that.moveCursor(e, this);
            });

            link.addEventListener('mouseout', function() {
                gsap.to(this.querySelector('span'), {
                    x: 0,
                    y: 0
                });
            });
        });
    }

    moveCursor(e, link) {
        const mousePosX = e.clientX;
        const mousePosY = e.clientY;

        gsap.to(cursor, 0, {
            x: mousePosX,
            y: mousePosY,
        });
    }

    moveTarget(e, link, span, force) {
        var boundingRect = link.getBoundingClientRect();
        var relX = e.pageX - boundingRect.left;
        var relY = (window.pageYOffset - (e.pageY - boundingRect.top))*-1;

        gsap.to(span, {
            x: (relX - boundingRect.width / 2) / boundingRect.width * force,
            y: (relY - boundingRect.height / 2) / boundingRect.height * force,
        });
    }

    render() {
        this.activateMagnetic();
    }
}

if (document.querySelectorAll('body:not(.isIE)').length > 0 && window.innerWidth > 1024) {
    const mouseCursor = new MouseCursor();
    mouseCursor.render();

    setTimeout(() => {
        const magneticCursor = new MagneticCursor();
        magneticCursor.render();
    }, 1000)
}
