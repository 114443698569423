import '@/css/app.css'
import 'swiper/css';

import "./mouse";

import Alpine from 'alpinejs'
import Autosize from '@marcreichel/alpine-autosize';
Alpine.plugin(Autosize);
Alpine.start();

import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Swiper from 'swiper';

const body = document.querySelector('body');

const projectSliders = () => {
    const defaultSliders = document.querySelectorAll(".project-slider");

    if (defaultSliders.length) {
        defaultSliders.forEach((slider) => {
            new Swiper(slider, {
                speed: 900,
                slidesPerView: 1.1,
                freeMode: true,
                loop: false,
                breakpoints: {
                    630: {
                        slidesPerView: 1.6,
                    },
                    768: {
                        slidesPerView: 1.8,
                    },
                    1024: {
                        slidesPerView: 2.3,
                    },
                },
                on: {
                    init: function () {
                        slider.style.opacity = 1;
                    },
                },
            });
        });
    }
};


const projectsHorizontalGsap = () => {
    const wrapper = document.querySelector('.project-wrapper');

    if (wrapper) {
        const projects = wrapper.querySelectorAll('.project');
        const block = document.querySelector('.home-intro');
        const intro = document.querySelector('.home-intro-text');
        const baseline = document.querySelector('.home-intro-baseline');
        const hideElements = document.querySelectorAll('.home-intro-hide');
        const projectLength = projects.length;

        ScrollTrigger.matchMedia({
            "(min-width: 1280px)": function () {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: block,
                        start: () => 'bottom bottom',
                        end: () => '+=' + projectLength * 800,
                        scrub: 1,
                        pin: true,
                        pinSpacing: 0,
                    }
                })

                tl.fromTo(projects, { x: '0' }, { x: () => ((projectLength - 1.75) * -100) + '%', delay: 0, duration: 1 }, 0);
                tl.fromTo(intro, { opacity: 1, scale: '1' }, { opacity: 0, scale: 0.95, delay: .017, duration: .1 }, 0);
                tl.fromTo(baseline, { rotate: 10 }, { rotate: () => -70, delay: 0, duration: 1 }, 0);
                hideElements.forEach(hideElement => {
                    tl.fromTo(hideElement, { opacity: 1 }, { opacity: 0, delay: .9, duration: .1 }, 0);
                })
            }
        });
    }
}

const marqueeAnimation = () => {
    let loops = gsap.utils.toArray('.text-single').map((line, i) => {
        const links = line.querySelectorAll(".js-text");
        return horizontalLoop(links, {
            repeat: -1,
            speed: 1 + i * 0.5,
            reversed: false,
            paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px"))
        });
    });

    let currentScroll = 0;
    let scrollDirection = 1;

    window.addEventListener("scroll", () => {
        let direction = (window.pageYOffset > currentScroll) ? 1 : -1;
        if (direction !== scrollDirection) {
            loops.forEach(tl => gsap.to(tl, {timeScale: direction, overwrite: true}));
            scrollDirection = direction;
        }
        currentScroll = window.pageYOffset;
    });

    function horizontalLoop(items, config) {
        items = gsap.utils.toArray(items);
        config = config || {};
        let tl = gsap.timeline({
                repeat: config.repeat,
                paused: config.paused,
                defaults: {ease: "none"},
                onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
            }),
            length = items.length,
            startX = items[0].offsetLeft,
            times = [],
            widths = [],
            xPercents = [],
            curIndex = 0,
            pixelsPerSecond = (config.speed || 1) * 100,
            snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
            totalWidth, curX, distanceToStart, distanceToLoop, item, i;
        gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
            xPercent: (i, el) => {
                let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
                xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"));
                return xPercents[i];
            }
        });
        gsap.set(items, {x: 0});
        totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
        for (i = 0; i < length; i++) {
            item = items[i];
            curX = xPercents[i] / 100 * widths[i];
            distanceToStart = item.offsetLeft + curX - startX;
            distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
            tl.to(item, {
                xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
                duration: distanceToLoop / pixelsPerSecond
            }, 0)
                .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {
                    xPercent: xPercents[i],
                    duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                    immediateRender: false
                }, distanceToLoop / pixelsPerSecond)
                .add("label" + i, distanceToStart / pixelsPerSecond);
            times[i] = distanceToStart / pixelsPerSecond;
        }

        function toIndex(index, vars) {
            vars = vars || {};
            (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
            let newIndex = gsap.utils.wrap(0, length, index),
                time = times[newIndex];
            if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
                vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
                time += tl.duration() * (index > curIndex ? 1 : -1);
            }
            curIndex = newIndex;
            vars.overwrite = true;
            return tl.tweenTo(time, vars);
        }

        tl.next = vars => toIndex(curIndex + 1, vars);
        tl.previous = vars => toIndex(curIndex - 1, vars);
        tl.current = () => curIndex;
        tl.toIndex = (index, vars) => toIndex(index, vars);
        tl.times = times;
        if (config.reversed) {
            tl.vars.onReverseComplete();
            tl.reverse();
        }
        return tl;
    }
};

const animatePage = function () {
    body.classList.add("is-loaded");
    const anchors = document.querySelectorAll("a:not(.no-animation)");

    anchors.forEach(function (a) {
        a.addEventListener("click", function (e) {
            e.preventDefault();
            const href = a.getAttribute("href");

            if (
                href.indexOf("javascript:;") >= 0 ||
                href.indexOf("mailto:") >= 0 ||
                href.indexOf("tel:") >= 0 ||
                href.substring(0, 1) === "#"
            ) {
                window.location.href = href;
                return false;
            }

            if (a.getAttribute("target") === "_blank" || e.shiftKey || e.ctrlKey || e.metaKey) {
                window.open(href);
            } else {
                body.classList.remove("is-loaded");
                setTimeout(() => {
                    window.location.href = href;
                },50)
            }
        });
    });
};

const scrollReveal = () => {
    setTimeout(()=> {
        ScrollTrigger.batch(".sr-item", {
            interval: 0.2,
            start: () => "top+=200 bottom",
            once: true,
            // markers: true,
            onEnter: (batch) =>
                gsap.fromTo(batch, { opacity: 0, duration: 800, scale: 0.96 }, { opacity: 1, scale: 1, stagger: 0.2 }),
        });

        ScrollTrigger.batch(".sr-item-text", {
            interval: 0.05,
            start: () => "top+=200 bottom",
            once: true,
            // markers: true,
            onEnter: (batch) =>
                gsap.fromTo(batch, { opacity: 0, duration: 800, y: 10, scale: 0.98 }, { opacity: 1, y: 0, scale: 1, stagger: 0.2 }),
        });
    },200)
};

const projectsAnimation = () => {
    const pitems = document.querySelectorAll('.project-wrapper .project > div');
    if (pitems.length) {
        setTimeout(() => {
            pitems.forEach( (pitem, i) => {
                setTimeout(() => {
                    console.log(pitems)
                    pitem.style.cssText = 'opacity: 1;transform: translateX(0);transition: opacity .7s, transform .7s;'
                }, i * 175);
            });
        }, 400);
    }
}

const validation = () => {
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
            document.querySelector('div[data-form-invalid]').classList.remove('hidden');
        }, false);
    });
}

const fileUpload = () => {
    const fileInputs = document.querySelectorAll('input[type=file]');
    fileInputs.forEach(input => {
        input.onchange = function() {
            let files = this.files, name = '';

            for (const [index, file] of Object.entries(files)) {
                name += (index != 0 ? ', ' : '') + file.name;
            }

            input.previousElementSibling.innerHTML = (files.length ? name : input.getAttribute('data-label'));
        };
    })
}

const scrollTo = () => {
    const scrollToElements = document.querySelectorAll('a[data-scroll]');

    scrollToElements.forEach(scrollToElement => {
        scrollToElement.addEventListener("click", (e) => {
            const scrollY = window.scrollY || window.pageYOffset;
            if(scrollToElement.getAttribute('data-scroll-direction') === 'y') {
                window.scrollTo({
                    top: getElementPosition(document.getElementById(scrollToElement.getAttribute('data-scroll')))['y'] + scrollY,
                    behavior: 'smooth',
                });
            } else {
                const scrollX = window.scrollX || window.pageXOffset;
                window.scrollTo({
                    top: getElementPosition(document.getElementById(scrollToElement.getAttribute('data-scroll')))['x'] + scrollX,
                    behavior: 'smooth',
                });
            }
        });
    });
}

const getElementPosition = (element) => {
    return element.getBoundingClientRect();
    // return rect.top + scrollY;
}

projectsHorizontalGsap();
marqueeAnimation();
validation();
fileUpload();
projectSliders();
scrollTo();
projectsAnimation();
animatePage();
scrollReveal();